<template>
    <div class="container d-flex align-items-center justify-content-center z-2">
        <div class="row mobile page-section g-0">
            <div class="col-sm-3 col-m-12 menu-section" style="background: white; position: relative;">
                <div class="row g-0">
                    <div class="col-12 d-flex justify-content-between">
                        <div class="btn" style="opacity: 0; cursor: initial;">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </div>
                        <button class="btn mobile-shown" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                            <img src="/img/ui-theme/menu-icon-black.svg" alt="Fullscreen">
                        </button>
                    </div>
                    <div class="col-12 dashboard-logo-section">
                        <div class="row g-0">
                            <div class="col-12" style="text-align: center;">
                                <p class="logo-text">85 SPRING</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 item-section mobile-hidden">
                        <span v-for="assetType in assetTypes">
                            <router-link
                            v-if="assetType.LinkName === 'quote'"
                            :key="assetType.Guid"
                            :to="{
                            name:  'TopLevelPdfViewDetail',
                            params: {
                                assetType: assetType.LinkName
                            }
                            }"
                            class="btn-theme-menu mb-1">
                                {{ assetType.ObjectName }}
                            </router-link>
                            <router-link
                            v-else
                            :key="assetType.Guid"
                            :to="{
                            name:  componentName,
                            params: {
                                assetType: assetType.LinkName
                            }
                            }"
                            class="btn-theme-menu mb-1">
                                {{ assetType.ObjectName }}
                            </router-link>

                        </span>
                    </div>
                    <div class="col-12 mobile-hidden align-items-center justify-content-around sticky-bottom" style="padding:10px; bottom: 0; text-align: center; position: absolute;">
                        <img src="/img/ui-theme/peligra.png" alt="Fullscreen" style="height: 2em; margin-bottom: 2em;">
                    </div>
                </div>

            </div>
            <div class="col-sm-9 col-m-0 video-section">
                <DashboardVideo />
            </div>
        </div>
    </div>
    <OffCanvas />
    <OffCanvasMenu />
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import OffCanvasMenu from "@/components/OffCanvasMenu.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
    name: "DashboardHome",
    components: {
        DashboardVideo,
        OffCanvas,
        OffCanvasMenu
    },
    data() {
        return {
        assetTypes: null,
        componentName: "AssetType",
        componentAdmin: "Admin",
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
        brochureUrl: "https://florian.metaluxe.com.au/brochure/MCO0024_Master%20brochure%2048pp_320x240_V9%20(2).pdf"
        }
    },
    created() {
        BunnyNetService.getFolders(this.folderStructure)
        .then(response => {
        // Remap array
        const menuItems = response.data.map((item) => {
            return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
            };
        });

        const customMenu = sortArray(menuItems, {
            by: ['LinkName'],
            order: 'LinkName',
            customOrders: {
            LinkName: this.theme.assetsMenuOrder
            }
        })
        
        this.assetTypes = customMenu
        })
        .catch(error => {
        console.log(error)
        })
    },
    setup () {
        const {theme} = global

        return {
        theme
        }
    }
};
</script>
