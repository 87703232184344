<template>
  <div class="container d-flex align-items-center justify-content-center z-2">
        <div class="row mobile align-content-between g-0">
            <div class="col-sm-3 col-m-12 h-100" style="background: white; position: relative;">
                <div class="row g-0">
                    <div class="col-12">
                        <router-link
                            :to="{
                                name:  'DashboardHome'
                            }"
                            class="btn">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </router-link>
                    </div>
                    <div class="col-12">
                        <div class="row g-0">
                            <div class="col-12" style="text-align: center; ">
                                <p class="logo-text">85 SPRING</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 item-section">
                        <div class="row p-0">
                            <div class="col-12">
                                <div class="btn-theme-menu">
                                    {{ this.assetType }}
                                </div>
                            </div>
                            <div class="col-12" style=" margin-top: 1em;">
                                <div class="row" style="padding: 0em 6em;">
                                    <div class="col-12" v-if="collection">

                                        <template v-for="asset in assetTypes" :key="asset.Guid">
                                            <!-- Video links -->
                                            <router-link
                                                v-if="asset.LinkName === 'videos' || asset.LinkName === 'examples'"
                                                :to="{
                                                name:  asset.ComponentName,
                                                params: {
                                                    assetType: this.assetType,
                                                    viewType: asset.LinkName,
                                                    lid: collection.videoLibraryId,
                                                    collectionId: collection.guid
                                                }
                                                }"
                                                class="btn-theme-menu mb-2"
                                            >
                                                <img :src="theme.videosIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                            </router-link>
                                            
                                            <!-- Panorama links -->
                                            <router-link
                                                v-if="asset.LinkName === 'panoramas' || asset.LinkName === 'virtual-tour' || asset.LinkName === 'balcony-views'"
                                                :to="{
                                                name:  asset.ComponentName,
                                                params: {
                                                    assetType: this.assetType,
                                                    viewType: asset.LinkName
                                                }
                                                }"
                                                class="btn-theme-menu mb-2"
                                            >
                                                <img :src="theme.panoramasIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                            </router-link>
                                            
                                            <!-- Renders links -->
                                            <router-link
                                                v-if="asset.LinkName === 'renders' || asset.LinkName === 'range'"
                                                :to="{
                                                name:  asset.ComponentName,
                                                params: {
                                                    assetType: this.assetType,
                                                    viewType: asset.LinkName
                                                }
                                                }"
                                                class="btn-theme-menu mb-2"
                                            >
                                                <img :src="theme.rendersIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                            </router-link>
                                            
                                            <!-- Floorplans links -->
                                            <router-link
                                                v-if="asset.LinkName === 'floorplans'"
                                                :to="{
                                                name:  asset.ComponentName,
                                                params: {
                                                    assetType: this.assetType,
                                                    viewType: asset.LinkName
                                                }
                                                }"
                                                class="btn-theme-menu mb-2"
                                            >
                                                <img :src="theme.floorplansIcon" :alt="asset.ObjectName" class="me-3">{{asset.ObjectName}}
                                            </router-link>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom" style="padding:10px; bottom: 0; text-align: center; position: absolute;">
                        <img src="/img/ui-theme/peligra.png" alt="Fullscreen" style="height: 2em; margin-bottom: 2em;">
                    </div>
                </div>
        
            </div>
            <div class="col-sm-9 col-m-0 h-100 mobile-hidden">
                <DashboardVideo />
            </div>
        </div>
    </div>
</template>

<script>
import AssetTypeVideo from '@/components/AssetTypeVideo.vue'
import TypeTopNav from '@/components/TypeTopNav.vue'
import TypeBotNav from '@/components/TypeBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "AssetType",
  props: {
    assetType: String
  },
  components: {
    AssetTypeVideo,
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      collection: null,
      componentName: "AssetView",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/'),
      assetOrder: null
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
    .then(response => {
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName
        };
      });

      // Assign type menu array
      if (this.assetType === 'demo') {
        this.assetOrder = this.theme.demoMenuOrder
      } else if (this.assetType === 'intro') {
        this.assetOrder = this.theme.introMenuOrder
      } else if (this.assetType === 'apartment-1') {
        this.assetOrder = this.theme.apartment1MenuOrder
      } else if (this.assetType === 'apartment-2') {
        this.assetOrder = this.theme.apartment2MenuOrder
      } else if (this.assetType === 'apartment-3') {
        this.assetOrder = this.theme.apartment3MenuOrder
      } else if (this.assetType === 'amenities') {
        this.assetOrder = this.theme.amenitiesMenuOrder
      } else if (this.assetType === 'exterior') {
        this.assetOrder = this.theme.exteriorMenuOrder
      }

      // Sort array
      const customMenu = sortArray(menuItems, {
        by: ['LinkName'],
        order: 'LinkName',
        customOrders: {
          LinkName: this.assetOrder
        }
      })
      
      this.assetTypes = customMenu
    })
    .catch(error => {
      console.log(error)
    }),

    // api call get video collection details
    BunnyNetService.getCollections(this.theme.videoLibraryId, this.assetType)
    .then(response => {
      
      this.collection = response.data[0]
    })
    .catch(error => {
      console.log(error)
    })
  },
  setup () {
    // load global config
    const {theme} = global

    return {
      theme
    }
  }
}
</script>