<template>
  <div class="offcanvas offcanvasmenu offcanvas-end" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasMenuLabel"></h5>
      <button type="button" class="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-menu-body" style="height: 100%;">
      <div class="row h-100 align-content-center">
        <div class="col-m-12 h-100" style="background: white; position: relative;">
                <div class="row g-0">
                    <div class="col-12">
                        <!-- <router-link
                            :to="{
                                name:  'DashboardHome'
                            }"
                            class="btn">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </router-link> -->
                        <button type="button" class="btn" data-bs-dismiss="offcanvas" aria-label="Close">
                            <img src="/img/ui-theme/back-hover-icon.svg" alt="Fullscreen" style="height: 1.5em;">
                        </button>
                    </div>
                    <div class="col-12">
                        <div class="row g-0">
                            <div class="col-12" style="text-align: center;">
                                <p class="logo-text">85 SPRING</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 item-section">
                        <span v-for="assetType in assetTypes">
                            <router-link
                            v-if="assetType.LinkName === 'quote'"
                            :key="assetType.Guid"
                            :to="{
                            name:  'TopLevelPdfViewDetail',
                            params: {
                                assetType: assetType.LinkName
                            }
                            }"
                            class="btn-theme-menu mb-1" @click="this.closeOffCanvas">
                                {{ assetType.ObjectName }}
                            </router-link>
                            <router-link
                            v-else
                            :key="assetType.Guid"
                            :to="{
                            name:  componentName,
                            params: {
                                assetType: assetType.LinkName
                            }
                            }"
                            class="btn-theme-menu mb-1" @click="this.closeOffCanvas">
                                {{ assetType.ObjectName }}
                            </router-link>

                        </span>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-around sticky-bottom" style="padding:10px; bottom: 0; text-align: right; position: absolute;">
                        <img src="/img/ui-theme/peligra.png" alt="Fullscreen" style="height: 2em; margin-bottom: 2em;">
                    </div>
                </div>
        
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import BunnyNetService from "@/services/BunnyNetService.js";
import global from '../global'
import { unslugify } from "unslugify";
import sortArray from 'sort-array'
export default {
  name: 'OffCanvasMenu',
  props: [
    'heading'
  ],
  data() {
    return {
        componentName: "AssetType",
        assetTypes: null,
        folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
    .then(response => {
      // Remap array
      console.log(response)
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName
        };
      });

      const customMenu = sortArray(menuItems, {
        by: ['LinkName'],
        order: 'LinkName',
        customOrders: {
          LinkName: this.theme.assetsMenuOrder
        }
      })
      
      this.assetTypes = customMenu
    })
    .catch(error => {
      console.log(error)
    })
  },
  methods: {
    closeOffCanvas: function() {
        document.querySelector('.offcanvas-backdrop').remove()
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>

<style scoped>
/* .btn-theme-menu {
  width: 60%;
  background: #000;
  color: #fff;
  font-weight: 300;
  margin: auto;
} */
.phone {
  color: #000;
  text-decoration: none;
}
</style>